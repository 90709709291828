// extracted by mini-css-extract-plugin
export var topNews = "News-module--topNews--2N1P_";
export var topNewsStory = "News-module--topNewsStory--2_RMI";
export var btmNews = "News-module--btmNews--Fi6pJ";
export var singleNews = "News-module--singleNews--3vj71";
export var singleNewsIn = "News-module--singleNewsIn--3pcSP";
export var singleNewsInn = "News-module--singleNewsInn--8KxQj";
export var fig = "News-module--fig--3hh2l";
export var singleCont = "News-module--singleCont--1Aezg";
export var newsexcerpt = "News-module--newsexcerpt--28_5I";
export var partnerCont = "News-module--partnerCont--1S7XB";
export var excerPad = "News-module--excerPad--1rA1u";